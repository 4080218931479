import React, {
  FunctionComponent,
  Key,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Switch,
  Transfer,
  TransferProps,
  Typography,
} from "antd";
import FormInput from "../../../../components/form-input/FormInput";
import FormRadio from "../../../../components/form-radio/FormRadio";
import { dataStatus } from "../../constants/data";
import { FormInstance } from "antd/lib";
import {
  getPermissionsRoles,
  updatePermissionsRoles,
} from "../../../../service/role-management/roleManagementApi";
import { get, isEmpty, toInteger } from "lodash";

type RecordType = {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
};

const validate = {
  nameRole: {
    required: true,
    message: "Vui lòng nhập tên vai trò",
  },
  description: {
    required: true,
    message: "Vui lòng nhập mô tả.",
  },
  status: {
    required: true,
    message: "Vui lòng chọn trạng thái.",
  },
};

type InfoRoleFormProps = {
  form?: FormInstance;
  checkEdit?: boolean;
  checkIsFormEdit?: boolean;
  isOpened?: boolean;
  dataEdit?: any;
  resetPermissions?: any;
  checkedList: string;
  setCheckedList: React.Dispatch<React.SetStateAction<string>>;
  defaultRole: any;
};

interface DefaultState {
  defaultSv: boolean;
  defaultQtcs: boolean;
  defaultGv: boolean;
}

const InfoRoleGeneralForm: FunctionComponent<InfoRoleFormProps> = ({
  checkEdit,
  checkIsFormEdit,
  isOpened,
  dataEdit,
  form,
  checkedList,
  setCheckedList,
  defaultRole
}) => {
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [dataTransfer, setDataTransfer] = useState<RecordType[]>([]);
  const [permissionsRole, setPermissionsRole] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(100);
  const [statusExists, setStatusExists] = useState(false);
  const [valueSearchLeft, setValueSearchLeft] = useState("");
  const [allPermissions, setAllPermissions] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(true);

  const fetchData = (dataSearch?: any) => {
    getPermissionsRoles(dataSearch).then((res) => {
      let { data } = res.data;
      data = mergeArraysDistinctByKey(data, allPermissions);
      setAllPermissions([...data]);
    });
  };

  const [defaultState, setDefaultState] = useState<DefaultState>({
    defaultSv: false,
    defaultQtcs: false,
    defaultGv: false,
  });

  useEffect(() => {
    // Map the selectedValue to the corresponding default state
    if (defaultRole !== undefined && defaultRole !== null)
      setCheckedList(defaultRole);
  }, [defaultRole]);

  

  useEffect(() => {
    if (checkIsFormEdit && dataEdit) {
      setStatusExists(true);
    }
  }, [dataEdit, statusExists]);

  useEffect(() => {
    if (!isOpened) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    if (!id) {
      setSelectedPermissions([]);
      setTargetKeys([]);
      setIsCreating(true);
      return;
    }
    setIsCreating(false);
    getPermissionsRoles({ id: toInteger(id), isExist: true }).then((res) => {
      let { data } = res.data;
      setSelectedPermissions(data);
    });
    fetchData({ pageSize: pageSize, keyword: "" });
  }, [isOpened]);

  useEffect(() => {
    fetchData({ pageSize: pageSize, keyword: valueSearchLeft });
  }, [pageSize, valueSearchLeft]);

  function mergeArraysDistinctByKey(arr1: any[], arr2: any[]) {
    const combined = [...arr1, ...arr2];
    const keySet = new Set();

    return combined.filter((item) => {
      if (keySet.has(item["id"])) {
        return false;
      }
      keySet.add(item["id"]);
      return true;
    });
  }

  const getNewData = () => {
    const newData: RecordType[] = mergeArraysDistinctByKey(
      allPermissions,
      selectedPermissions
    ).map((role: any) => {
      return {
        key: role.id.toString(),
        title: role.name,
        description: role.name,
        chosen: statusExists,
      };
    });
    setDataTransfer(newData);
    if (!isCreating) {
      const targetKey = selectedPermissions.map((permission: any) =>
        String(permission.id)
      );
      setTargetKeys([...targetKey]);
    }
  };

  useEffect(() => {
    getNewData();
  }, [permissionsRole, statusExists, selectedPermissions, allPermissions]);

  const loadMore = () => {
    setPageSize(pageSize + 100);
  };

  const CheckboxGroup = Checkbox.Group;

  const handleCheckboxChange = (value: string) => {
    if (checkedList === value) {
      setCheckedList("");
      form?.setFieldValue("macDinh", "");
    } else {
      setCheckedList(value);
      form?.setFieldValue("macDinh", value);
    }
  };
  const onChangeGroupCheckBox = (list: string[]) => {
    // setCheckedList(list);
    // if (checkedList.length > 1) {
    //   const latestCheckedValue = checkedList[checkedList.length - 1];
    //   setCheckedList([latestCheckedValue]);
    // } else {
    //   setCheckedList(list);
    // }
    // setCheckedList(list);
  };

  return (
    <>
      <Row gutter={16}>
        <Col className="col-form-class" span={12}>
          <Form.Item
            name="ten"
            rules={[validate.nameRole]}
            layout="vertical"
            label="Tên vai trò"
            // labelCol={{ span: 24 }}
            // wrapperCol={{ span: 24 }}
          >
            <FormInput placeholder="Nhập tên vai trò" disabled={checkEdit} />
          </Form.Item>
        </Col>
        <Col className="col-form-class" span={12}>
          <Form.Item
            label="Trạng thái"
            name="trangThai"
            rules={[validate.status]}
            layout="vertical"
            // labelCol={{ span: 24 }}
            // wrapperCol={{ span: 24 }}
          >
            <FormRadio data={dataStatus} value={true} disabled={checkEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="col-form-class-2" span={24}>
          <Form.Item
            layout="vertical"
            //  labelCol={{ span: 24 }}
            //  wrapperCol={{ span: 24 }}
            label="Mô tả"
            name="moTa"
            rules={[validate.description]}
          >
            <Input.TextArea
              placeholder="Nhập mô tả vai trò"
              disabled={checkEdit}
              showCount
              maxLength={100}
              autoSize={{ minRows: 2, maxRows: 3 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="col-form-class" span={24}>
          <Form.Item
            label={
              <p className="label-row">
                {"Mặc định" + " "}
                <p className="label-special">
                  {" "}
                  (Vai trò được gán mặc định khi thêm mới tài khoản)
                </p>
              </p>
            }
            name="macDinh"
            layout="vertical"
            // rules={[validate.description]}
          >
            <Col className="col-checkbox" span={24}>
              <Checkbox
                value={"defaultQtcs"}
                disabled={checkEdit}
                checked={checkedList === "defaultQtcs"}
                onChange={() => handleCheckboxChange("defaultQtcs")}
              >
                Quản trị cơ sở
              </Checkbox>
            </Col>
            <Col className="col-checkbox" span={24}>
              <Checkbox
                value={"defaultGv"}
                disabled={checkEdit}
                checked={checkedList === "defaultGv"}
                onChange={() => handleCheckboxChange("defaultGv")}
              >
                Giảng viên
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox
                value={"defaultSv"}
                disabled={checkEdit}
                checked={checkedList === "defaultSv"}
                onChange={() => handleCheckboxChange("defaultSv")}
              >
                Sinh viên
              </Checkbox>
            </Col>
          </Form.Item>
        </Col>

        {/* <Form.Item name="defaultSv" valuePropName="checked">
          <Checkbox>
          </Checkbox>
        </Form.Item>
        <Form.Item name="defaultQtcs" valuePropName="checked">
          <Checkbox>
          </Checkbox>
        </Form.Item>
        <Form.Item name="defaultGv" valuePropName="checked">
          <Checkbox>
          </Checkbox>
        </Form.Item> */}

        {/* <Form.Item name="defaultSv" initialValue={defaultState.defaultSv}>
          <input
            type="hidden"
            value={defaultState.defaultSv}
          />
        </Form.Item>
        <Form.Item name="defaultQtcs" initialValue={defaultState.defaultQtcs}>
          <input
            type="hidden"
            value={defaultState.defaultQtcs}
          />
        </Form.Item>
        <Form.Item name="defaultGv" initialValue={defaultState.defaultGv}>
          <input
            type="hidden"
            value={defaultState.defaultGv}
          />
        </Form.Item> */}
      </Row>
    </>
  );
};

export default InfoRoleGeneralForm;